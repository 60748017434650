import * as React from "react";
import Carousel from 'react-bootstrap/Carousel';

import detail1 from "../images/1m-homedance/detail-main.mp4"
import detail2 from "../images/1m-homedance/detail-login.mp4"
import detail3 from "../images/1m-homedance/detail-purchase.mp4"

import '../components/carousel.css';

function FadeCarousel() {
  return (
    <Carousel className="bg-191919 py-5 px-3 r-24 mx-3 my-4" interval={null}>
      <Carousel.Item>
        <div className="row text-white mx-3 px-5 py-5">
          <div className="col-lg-6 text-center">
            <video className="px-md-auto" loop muted autoPlay={ true } width="200">
              <source src={ detail1 }></source>
            </video>
          </div>
          <div className="my-lg-auto col-lg-6 text-center text-lg-start pe-lg-5 mt-3">
            <div className="mb-3 mb-lg-4 pe-lg-3">
              <p className="h1 fw-700 fw-18E77F">온보딩</p>
            </div>
            <div className="mb-3 mb-lg-4 pe-lg-3">
            <p className="h6 fw-700">어플리케이션 소개부터<br></br>원밀리언 홍보까지 한 페이지로 완성.</p>
            </div>
            <div className="mb-3 mb-lg-4 pe-lg-3">
              <p>홈댄스 컨텐츠 썸네일을 사용한 시선을 끄는 <span className="fc-18E77F">헤더 이미지와 문구</span>
               시작을 부르는 초록 <span className="fc-18E77F"><spam lang="en">Call-To-Action</spam> 버튼</span></p>
            </div>
            <div className="mb-3 mb-lg-4 pe-lg-3">
              <p>원밀리언 댄스 스튜디오 초심자를 위한 <span className="fc-18E77F">간단한 소개</span>와 
              매일 매일 업데이트 되는 <span className="fc-18E77F">최신 안무 영상</span>까지</p>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row text-white mx-3 px-5 py-5">
          <div className="my-lg-auto col-lg-6 text-center text-lg-start ps-lg-5 mt-3">
            <div className="mb-3 mb-lg-4 px-lg-3">
              <p className="h1 fw-700 fw-18E77F">로그인</p>
            </div>
            <div className="mb-3 mb-lg-4 px-lg-3">
              <p className="h6 fw-700">단순한 로그인에 로그인 확인 화면을 추가해<br></br>
              TV 애플리케이션 내 로그인을 확실하게.</p>
            </div>
            <div className="mb-3 mb-lg-4 px-lg-3">
              <p>모바일 디바이스로 <span className="fc-18E77F">QR 코드를 스캔해 고유 로그인 페이지</span>에서 로그인</p>
            </div>
            <div className="mb-3 mb-lg-4 px-lg-3">
              <p>웹에서 로그인 후, TV에서 로그인까지 평균 10~15초 네트워크 환경에 따라 최대 1분까지 소요됨을 
                고려해 <span className="fc-18E77F">로그인 완료 화면</span>을 추가해 <span className="fc-18E77F">사용자에게 올바른 결과에 도달했음을 전달</span></p>
            </div>
          </div>
          <div className="col-lg-6 text-center">
            <video className="px-md-auto" loop muted autoPlay={ true } width="200">
              <source src={ detail2 }></source>
            </video>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row text-white mx-3 px-5 py-5">
          <div className="col-lg-6 text-center">
            <video className="px-md-auto" loop muted autoPlay={ true } width="200">
              <source src={ detail3 }></source>
            </video>
          </div>
          <div className="my-lg-auto col-lg-6 text-center text-lg-start pe-lg-5 mt-3">
            <div className="mb-3 mb-lg-4 pe-lg-3">
              <p className="h1 fw-700 fw-18E77F">상품 결제</p>
            </div>
            <div className="mb-3 mb-lg-4 pe-lg-3">
            <p className="h6 fw-600">한미 동시 오픈을 위한 해외 결제 기능부터 <br></br>TV 앱의 낮은 사용성을 높여주는 결제 수단 저장까지</p>
            </div>
            <div className="mb-3 mb-lg-4 pe-lg-3">
              <p>사용자가 TV 앱에서 선택한 상품에 대한 <span className="fc-18E77F">상세 정보 제공</span>과 
              <span className="fc-18E77F">국내와 해외 결제 시스템 모두 구축</span></p>
            </div>
            <div className="mb-3 mb-lg-4 pe-lg-3">
              <p>모바일 디바이스로 QR 코드를 스캔해 결제 페이지 들어오는 번거로운 과정을 상품 결제 때마다 반복해야 
                한다는 점을 고려해 결제의 과정을 한 단계 줄이기 위해 <span className="fc-18E77F">결제 수단 저장 기능 제공</span></p>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default FadeCarousel;