import * as React from "react"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Header from '../components/header'
import StickyNav from '../components/stickynav'
import Flush from '../components/flush'
import Carousel from '../components/carousel'
import CardCol from '../components/card'
import Footer from '../components/footer'

import '../components/font.css';
import '../components/stylesheet.css';

import mockup from "../images/1m-homedance/1mhd-top-img.png"
import sketch from "../images/sketch-bdblack.svg"
import illustrator from "../images/ai-bdblack.svg"
import zeplin from "../images/zeplin-bdblack.svg"
import dg from "../images/1m-homedance/1mhd-dg.png"

import detail1 from "../images/1m-workshop/details-content-1.png"
import detail2 from "../images/1m-workshop/details-content-2.png"
import detail3 from "../images/1m-workshop/details-content-3.png"
import detail4 from "../images/1m-workshop/details-content-4.png"

import card5678 from "../images/5678-card-img.png"
import card1mws from "../images/1mws-card-img.png"
import card1mhd from "../images/homedance-card-img.png"


const itemLink = [
  {text: "소개", destination: "#top",},
  {text: "기능", destination: "#feature",},
  {text: "디자인 가이드", destination: "#designGuide",},
  {text: "후기", destination: "#review",},
]

const sftwrList = [
  {name: "sketch", src: sketch},
  {name: "illustrator", src: illustrator},
  {name: "zeplin", src: zeplin}
]

const imgArr = [
  {title: "detail1", src: detail1},
  {title: "detail2", src: detail2},
  {title: "detail3", src: detail3},
  {title: "detail4", src: detail4}
]

// markup
const Project3 = () => {
  return (
    <main lang="ko" className="bg-F5F5F7">
      <title>1M HomeDance</title>
      <Header />
      <StickyNav href="/1m-homedance" bgColor="white" textColor="black" title="1M HomeDance" itemArr={ itemLink }/>
      <div className="scrollspy text-black bg-F5F5F7" id="omhd"
      data-bs-spy="scroll"
      data-bs-target="#subnav"
      data-bs-root-margin="0px 0px -40%"
      data-bs-smooth-scroll="true"
     tabindex="0">
        <div className="px-4 mt-5" id="top">
        <AnimationOnScroll animateIn="animate__fadeIn">
        <div className="text-center">
            <h1 className="display-title fw-700 pb-3 mb-4" lang="en">1M HomeDance</h1>
            <img src={ mockup } width="100%" className="mb-4" />
          </div>
        </AnimationOnScroll>
        <div className="text-start mx-lg-15p">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <p className="fs-1 fw-700 mt-5">TV와 모바일의 완벽한<br></br>
              <span className="fc-15CF72" lang="en">CONNECTION.</span></p>
            <p className="fs-5 fw-600 w-md-60 lh-base pe-lg-5">
            <span lang="en">LG TV x 1MILLION, Web OS TV</span>앱 <span lang="en">HomeDance</span>
            의 모바일 전용 웹사이트. <span lang="en">TV</span> 앱의 낮은 사용성을 대폭 상승시키는 최적의 웹사이트로 회원가입부터
            상품 결제까지 <span lang="en">HomeDance</span>와 완벽히 연결된 모바일 전용 웹사이트를 소개합니다.
            </p>
            <a href='https://xd.adobe.com/view/ed560702-1404-45ba-85f7-584a92683916-b805/?fullscreen'  target="_blank">
              <button
              className="fs-5 button-text mb-5 fw-700 p-0 fc-15CF72">프로토타입 보기 
              <span lang="en" className="fw-400 fc-15CF72"> 􀆊</span>
              </button>
            </a>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Flush
            team="PO, UI Designer, Front-End Developer, Back-End Developer, Content Producer"
            contribute="Overall 90% | UX Design 80%"
            role="Product Owner / Project Owner"
            roleDscrpt="신규 서비스 기획, 시장 조사, 브랜딩 구축, 와이어프레임, 플로우 차트, 스토리보드 등 UX 구축, 제품 프로토타이핑"
            softwares={ sftwrList }></Flush>
        </AnimationOnScroll>
          </div>
        </div>
        <div className="px-4 py-5 mt-5" id="feature">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="text-start mx-lg-15p">
              <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Features</p>
              <p className="fw-section w-md-60 fw-700 mb-5">됐나? 됐다!<br></br>모든것을 확실하게.</p>
              <Carousel className="pt-4 mx-lg-auto my-4" />
            </div>
          </AnimationOnScroll>
        </div>
        <div className="px-4 py-5 bg-191919 text-white" id="designGuide">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="text-start mx-lg-15p">
              <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Design Guide</p>
              <p className="fw-section w-md-60 fw-700">자꾸만 끌리는.<br></br>눈이 편안한 고대비.</p>
              <img src={ dg } width="90%" className="pt-4 mx-auto my-4" />
            </div>
            </AnimationOnScroll>
        </div>
        <div className="px-4 py-5 mx-lg-15p" id="review">
          <AnimationOnScroll animateIn="animate__fadeIn">
          <div className="text-start">
            <p className="m-0 fs-6 fw-600 fc-gray" lang="en">Review</p>
            <p className="fw-section w-md-60 fw-700">프로젝트를 마무리하며</p>
          </div>
          <p className="my-3 my-lg-5">
            일단 원밀리언 홈댄스 서비스는 나의 담당 프로젝트가 아니었지만, 
            일정을 맞추기 위해 중간에 투입되었다. 이사님의 부탁으로 신입 PO를 도우려고 킥오프 단계에서부터 
            모든 회의에 참여하기는 했지만, 나의 프로젝트가 워낙 바빴다고 보니까 서비스에 대한 이해도가 높은 
            편은 아녔다. 다행히 그동안 회의에 참석하고 피드백을 주던 과정 덕에 빠르게 따라잡아 웹사이트 기획과 
            디자인을 모두 금방 시작할 수 있었다. TV에서 QR코드 인식을 통해 연결되는 회원가입/로그인, 결제 기능만 
            구성하면 되어 빠르게 진행할 수 있겠다고 생각했지만, 랜딩 페이지, 계정관리 페이지 등 규모가 커지면서 
            브랜딩부터 관여하며 웹페이지를 기획해나가게 되어서 조금 버거웠다. 또한 입사 때부터 함께 일했던 
            개발자들과 일해서 일정을 산정하거나 업무를 배분하는 데 어려움이 없었다는 점이 일을 진행하는 데 많은 
            도움이 됐다.
          </p>
          <p className="my-3 my-lg-5">
            이 프로젝트를 통해 작은 스타트업에는 내 프로젝트, 네 프로젝트는 없다는 걸 알았다. 
            대기업에 비해 인원이 적다 보니 개발자와 디자이너를 나누어 쓰는 경우는 있었지만, 작은 부분이라도 기획까지 
            동시에 맡는 일은 없었지만, 이번 일로 기획도 나누어 쓰는 일이 생긴다는 걸 알았다. 그래도 이 프로젝트 담당 
            PO와 함께 서비스에 대해서 고민하면서 나의 주 프로젝트에 관한 시야도 더 넓힐 수 있었다. 
            (이 프로젝트를 하면서 생각나 아이디어로 추가된 기능이 실제로 있다) 함께 일한 개발자들 또한 이 기회로 
            새로운 기술을 연구하는 시간을 가질 수 있었다는 점이 좋았다. 나와 팀의 능력 향상과 더불어 좋은 
            결과물과 성공적인 원밀리언 홈댄스 데뷔에 한 부분을 차지했다는 점에서 만족스러웠다.
          </p>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="bg-white">
        <div className="pt-4 pb-5 mx-0 text-center bg-white" id="case-study">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOut="animate__fadeOut">
            <h6 className="fw-600 mt-5" lang="en">Case Study</h6>
            <h1 className="display-3 fw-700 pb-3 blue-gradient">이제는 증명할 시간.</h1>
            <p className="mx-5 mb-0 fw-600">아래에서 마음에 드는 것을 골라 탐구해보세요.</p>
            <p className="mx-5 fw-600">신규 서비스 기획/디자인부터 스크럼 팀 리드까지 저의 작업 과정, 스타일 등을 심층 탐구 할 수 있습니다.</p>
            <Row xs={1} md={3} className="g-4 mt-4">
              <CardCol link="/5six7eight" imgSrc={ card5678 } title="5six7eight Creative Studio"
              text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
              <CardCol link="/1m-workshop" imgSrc={ card1mws } title="1M Workshop"
              text="기존의 워크샵 예약 FLOW를 보완한 어떤 워크샵 이벤트에서도 사용할 수 있는 Universal All-in-1 웹사이트를 소개합니다." />
              <CardCol link="/1m-homedance" imgSrc={ card1mhd } title="1M HomeDance"
              text="5678에서 수업을 진행하는 안무가들이 걱정없이 수업에 집중할 수 있게 관리해주고 최적의 온라인 수업 환경을 제공합니다." />
            </Row>
          </AnimationOnScroll>
      </div>
      </div>
      <Footer bgColor="black" textColor="white" />
    </main>
  )
}

export default Project3